<template>
  <vuestic-widget :loading="loading">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">Business Accounts<span class="inst_av">( Deleted ) </span></div>
        <div class="d-flex justify-content-end align-items-center">
          <div>
            <b-nav pills>
              <b-nav-item><router-link :to="{name: 'businessAccountsActive'}">Active</router-link></b-nav-item>
              <b-nav-item><router-link :to="{name: 'businessAccountsArchived'}">Archived</router-link></b-nav-item>
              <b-nav-item active><router-link :to="{name: 'businessAccountsDeleted'}">Deleted</router-link></b-nav-item>
            </b-nav>
          </div>
          <div class="searchForm pl-2">
            <text-input name="search" v-model="search" placeholder="Search..." style="width: 250px"></text-input>
            <button class="btn btn-default btn-primary btn-sm ml-2" @click="handleSearch()" :disabled="!search">
              <i class="fa fa-search"></i> Filter
            </button>
            <button class="btn btn-default btn-primary btn-sm ml-2" @click="clearSearch()">
              Reset
            </button>
          </div>
        </div>
      </div>
    </template>
    <datatable v-bind="$data" class="le-datatable" />
  </vuestic-widget>
</template>

<script>
import components from "../../common/tables/comps";
import ActionsDeleted from "./Actions/ActionsDeleted";

export default {
  data() {
    return {
      loading: false,
      supportBackup: false,
      supportNested: false,
      HeaderSettings: false,
      tblClass: "table-bordered",
      tblStyle: "color: #666",
      pageSizeOptions: [10, 25, 50, 100],
      columns: (() => {
        const cols = [
          {
            title: 'ID',
            field: 'id',
            label: 'Business ID',
            sortable: true,
            visible: true
          },
          {
            title: 'Business Name',
            sortable: true,
            field: 'name',
            tdStyle: { fontStyle: 'normal' }
          },
          {
            title: 'Total Installs',
            field: 'count_active_installs',
            sortable: true,
            visible: true,
            tdClass: 'center'
          },
          {
            title: "Operation",
            tdComp: ActionsDeleted,
            visible: "true",
            tdClass: "center"
          }
        ];
        return cols;
      })(),
      data: [],
      total: 0,
      // selection: [],  //if this is present the row selector shows up..
      summary: {},
      query: {
        search: null,
      },
      search: null,
      // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
      xprops: {
        eventbus: new Vue()
      }
    };
  },
  watch: {
    query: {
      handler() {
        this.handleQueryChange();
      },
      deep: true
    }
  },
  methods: {
    handleSearch () { 
      this.query.search = this.search
    },

    clearSearch () {
      this.search = null
      this.query.search = null
    },
    
    handleQueryChange() {
      this.data = []
      this.total = 0;
      this.loading = true;
      const query = {...this.query, status: 'deleted'};
      this.$store.dispatch("business/list", query).then(({ data, meta }) => {
        this.data = data;
        this.total = meta.total;
        this.loading = false;
      });
    }
  }
};
</script>

<style>
</style>
